.about {
  padding: 0 1rem 0 1rem;
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-content {
  width: 500px;
}
.about-title {
  text-align: center;
}
