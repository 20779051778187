@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");

body {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Cutive Mono", monospace;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

html {
  margin: 0;
  padding: 0;
}

* {
  cursor: url("images/cursor.png"), default;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

@media(hover: hover) and (pointer: fine) {
  a:hover {
    cursor: url("images/hover.png"), crosshair;
    background-image: linear-gradient(0deg, #28527a, #8ac4d0);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

a:visited {
  color: black;
}
