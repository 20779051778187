.home {
  flex: 0 1 100%;
  width: 100%;
}

.home-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-menu a {
  margin: 0.5rem 0 0.5rem;
}

.home-menu-email {
    position: relative;
}