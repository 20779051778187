.resume {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.resume-document-container {
  overflow: auto;
  width: min(100vw, 1020px);
}

.resume-loading {
  height: 100%;
  display: flex;
  align-items: center;
}

.resume-download {
  width: min(100vw, 1020px);
  text-align: right;
}