@import url(https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap);
body {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Cutive Mono", monospace;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

html {
  margin: 0;
  padding: 0;
}

* {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAANklEQVQ4T2NkoBAwUqifYRgb8B9H2GB4GVcYgAxAl8MmhjMQh4kB2MKR6EAkOoEO45RIdBgAAHx0ChHUisaBAAAAAElFTkSuQmCC), default;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

@media(hover: hover) and (pointer: fine) {
  a:hover {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAOUlEQVQ4T2NkoAAwUqCXYfBq/s/AgNt1hJxNX80g22AA5DJ0Pookvtiir7ORXUKRzXgTIKGoGqSaAWjNCBA9uGfyAAAAAElFTkSuQmCC), crosshair;
    background-image: linear-gradient(0deg, #28527a, #8ac4d0);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

a:visited {
  color: black;
}

.app{height:100vh;width:100vw;display:flex;flex-direction:column}
.header{margin:1rem 1rem;box-sizing:border-box}
.home{flex:0 1 100%;width:100%}.home-menu{height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.home-menu a{margin:.5rem 0 .5rem}.home-menu-email{position:relative}
.resume{width:100%;height:100%;display:flex;flex-direction:column;align-items:center;overflow:hidden}.resume-document-container{overflow:auto;width:min(100vw, 1020px)}.resume-loading{height:100%;display:flex;align-items:center}.resume-download{width:min(100vw, 1020px);text-align:right}
.about{padding:0 1rem 0 1rem;flex:0 1 100%;width:100%;display:flex;align-items:center;justify-content:center}.about-content{width:500px}.about-title{text-align:center}
